import { table } from 'console';
import React from 'react';
import { RouteComponentProps, Redirect } from "react-router-dom";
import { PaymentForm } from './PaymentForm';

export interface InvoiceSearchProps {
	invoiceUrl? : string,
	invoiceId? : string,
	clientId?: string,
	amount?: string,
}

export interface Payment {
	paymentAmount: number,
	paymentDate: string
}

export interface InvoiceState {
	amountRemaining?: number,
	paymentsMade?: Array<Payment>,
	totalPaid?: number,
	amount? : number,
	status? : 'OPEN' | 'PAID'| 'WRITEOFF' | 'CANCELLED',
	invoiceUrl?: string,
	invoiceId? : string,
	dueDate? : string,
	invoice?: string,
	projectName?: string,
	clientProjectId?: string,
	clientName?: string,
	customerURL?: string,
	queryState?: "loading" | "notFound" | "serviceFailure" | "found" ,
	viewed?: number,
	showPaymentForm? : boolean,
	redirect: boolean

}

export interface InvoiceRouteProps extends RouteComponentProps<InvoiceSearchProps> { }


export class Invoice<P,S> extends React.Component<InvoiceSearchProps | P, InvoiceState> {
	constructor (props: InvoiceSearchProps | P) {
		super(props);
		this.handleShowPayment = this.handleShowPayment.bind(this);
		this.handlePaymentRefresh = this.handlePaymentRefresh.bind(this);
		this.state = {
			showPaymentForm: false,
			redirect: false
		};

	}

	async fetchInvoiceByUrl (invoiceUrl: string) {
		return this.fetchInvoice(invoiceUrl.concat("?include_file=true"));
	}

	public async fetchInvoiceBySearchParameters (params: InvoiceSearchProps) {
		return this.fetchInvoice("?includeFile=true&amount=".concat(params.amount || "").concat("&invoiceId=").concat(params.invoiceId || "").concat("&clientId=").concat(params.clientId || ""));
	}


	async fetchInvoice (query: string)  {
		console.log ("Calling fetch for "+ query);
		let api_key = process.env.REACT_APP_API_KEY;
		let api_endpoint = process.env.REACT_APP_API_ENDPOINT;
		let	base_url = "https://".concat(api_endpoint || "").concat("/invoice/");

		console.log("api_endpoint is ".concat(api_endpoint || "none"));
		if ((api_key !== undefined) && (api_endpoint !== undefined)) {
			this.setState({
				queryState: "loading"
			});
			let url = base_url.concat(query);
			const response = await fetch(url, {
				headers: {
				'Content-Type': 'application/json',	
				'x-api-key':api_key
				},

			});

			//Attempt to reset Invoice Table data
			this.setState({paymentsMade: [], totalPaid: 0, amountRemaining: undefined});

			return await response.json();
		}

	}

	// callback for fetchInvoice Promise
	public handleFetchInvoiceResponse (response: InvoiceState) {
		this.setState({
			...(response as InvoiceState),
			queryState: "invoiceUrl" in response? "found" : "notFound",
			showPaymentForm: false
		});
	} 

	public handleFetchInvoiceError (response: InvoiceSearchProps) {
		this.setState({
			queryState: "serviceFailure"
		});
	}

	handleShowPayment (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		this.setState({
			...this.state,
			showPaymentForm: true
		});
	}

	public handlePaymentRefresh () {
		if (this.state.invoiceUrl)
			this.fetchInvoiceByUrl(this.state.invoiceUrl).then (response => this.handleFetchInvoiceResponse(response)).catch(response => this.handleFetchInvoiceError(response));
	}

	componentDidMount () {
		let invoiceUrl = undefined;
		if ("invoiceUrl" in this.props) {
			invoiceUrl = (this.props as InvoiceSearchProps).invoiceUrl;
		}
		  

		if (invoiceUrl) {
			this.fetchInvoiceByUrl(invoiceUrl).then (response => this.handleFetchInvoiceResponse(response)).catch(response => this.handleFetchInvoiceError(response));
		}
		else if ("amount" in this.props) {
			this.fetchInvoiceBySearchParameters (this.props as InvoiceSearchProps).then (response => this.handleFetchInvoiceResponse(response)).catch(response => this.handleFetchInvoiceError(response));
		}
		else {
			this.setState ({
				queryState: "notFound"
			});
		}
	}

	public setSeeAllInvoicesRedirect = () => {
		this.setState({ redirect: true});
	}

	public renderSeeAllInvoicesRedirect = (customerURL: string) => {
		//Customer URL will be used to navigate to each Customer's individual page
		let url = "/customer/" + customerURL;
		if (this.state.redirect) {
			return <Redirect to={url}/>
		}
	}

  render() {
		if (this.state.queryState === "found") {
			console.log("status is " + this.state.status);

			let invoiceDescripton = <span>Invoice #<strong>{this.state.invoiceId}</strong> from project #<strong>{this.state.clientProjectId} {this.state.projectName}</strong> for <strong>${ this.state.amount }</strong></span>;
			let invoiceMessage = undefined;

			
			switch (this.state.status) {
				case "OPEN":
					let paymentOption = undefined;
					//Set amountDue to be amountRemaining, if payments have been made, other set it to total amount on the invoice
					let amountRemaining = undefined;
					let amountDue;
					amountRemaining = this.state.amountRemaining;
					if (amountRemaining) {
						amountDue = amountRemaining;
					} else {
						amountDue = this.state.amount;
					}

					if (this.state.showPaymentForm && this.state.invoiceId !== undefined && this.state.amount !== undefined && this.state.invoiceUrl !== undefined && amountDue !== undefined) {
						paymentOption = <PaymentForm invoiceUrl={this.state.invoiceUrl} amountDue={amountDue} paymentRefreshAction={this.handlePaymentRefresh} isOnCustomerPage={false}/>		
					}
					else {
						paymentOption = <button className="btn btn-success btn-md" onClick={this.handleShowPayment }>Pay now</button>
					}
					let paymentTable = undefined;
					let paymentList = undefined;
					if (this.state.paymentsMade && (this.state.paymentsMade.length > 0)){
						paymentList = this.state.paymentsMade.map((payment, index) =>
								<tr key={"Payment " + index}>
									<td key={"Payment Amount " + index}>${payment.paymentAmount} </td>
									<td key={"Payment Date " + index}>{payment.paymentDate}</td>
								</tr>
						);
						paymentTable = 
						<div className="panel panel-default" style={{marginTop:12}}>
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>Payment Amount</th>
										<th>Payment Date</th>
									</tr>
								</thead>
								<tbody>
									{paymentList}
									<tr>
										<th>Remaining Balance: ${this.state.amountRemaining}</th>
										<th></th>
									</tr>
								</tbody>
							</table>
						</div>
					}
					invoiceMessage = <div>
							{invoiceDescripton}<span> is <strong>{ this.state.status}</strong> with due date <strong>{ this.state.dueDate }</strong>.</span><br/>
							{ this.state.customerURL ? <button className="btn btn-success btn-md " style={{marginTop: 10}} onClick={this.setSeeAllInvoicesRedirect}>See all open Invoices</button> : "" }
							{paymentTable ? <div>{paymentTable}</div>: <div><br/><br/></div>}{paymentOption}
						</div>; 
					break;
				case "PAID":
					invoiceMessage = <div>{invoiceDescripton}<span> has been paid, thank you!<br /><strong>Please print this page for your records.</strong></span></div>

					break;
				default:
					invoiceMessage = <div>{invoiceDescripton}<span> has been cancelled or written off.</span></div>;
			}

			//For IE using atob convert base64 encoded data to byte array and show a view invoice link, otherwise embed the invoice PDF in the page
			let blob:Blob;
			let invoiceDisplay:any;
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				var byteCharacters = atob(this.state.invoice || "");
				var byteNumbers = new Array(byteCharacters.length);
				for (var i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				var byteArray = new Uint8Array(byteNumbers);
				blob = new Blob([byteArray], { 
						type: 'application/pdf'
				});
				invoiceDisplay = <div><strong><a href="#" onClick={ () => {window.navigator.msSaveOrOpenBlob(blob, "invoice.pdf");}}>View Invoice</a></strong>
						<br />
						<div>For a better experience on this site, consider using the <a href="https://www.google.com/chrome/">Chrome</a> browser.</div>
					</div>;
			}
			else {
				invoiceDisplay = <embed src={ "data:application/pdf;base64, ".concat(this.state.invoice || "")} width="80%" height="800" title={ "Invoice ".concat(this.state.invoiceId || "") }></embed>
			}

			return (
				<div>
					<div className="container" style={{marginBottom: 20}}>
						<div className="row">
							{invoiceMessage}
						</div>
					</div>
					<div id="invoice">
						{invoiceDisplay}
					</div>		
					{this.state.customerURL ? this.renderSeeAllInvoicesRedirect(this.state.customerURL) : console.log("CUSTOMER URL IS NULL")}			
				</div>
			);
		}
		else if (this.state.queryState === "notFound") {
			return <div>Invoice not found.</div>;
		}
		else if (this.state.queryState === "loading") {
			return <div>Loading...</div>;
		}
		return <div>Service failure, try again later or contact support.</div>
  }
}
