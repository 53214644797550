import React from 'react';
import { render } from 'react-dom';
import { PaymentForm } from './PaymentForm';
import {Checkbox} from './Checkbox';
import { GenericType } from 'typescript';
import { RouteComponentProps } from 'react-router-dom';
import {Redirect} from 'react-router-dom'; 


export interface CustomerPageProps {
    
}

export interface Invoice {
  balanceDue: string,
  dueDate: string,
  invoiceID: number,
  invoiceURL: string,
}

export interface CustomerPageState {
  showPaymentForm: boolean,
  invoicesToBePaid: Array<Invoice>,
  amountDue: number,
  invoiceArray: Array<Invoice>,
  url: Location,
  redirect: boolean,
  invoiceTableKey: number,
  paymentFormKey: number
}

export interface Invoice {
	invoiceID: number,
  invoiceURL: string,
  balanceDue: string,
  dueDate: string
}

export class CustomerPage extends React.Component<CustomerPageProps & RouteComponentProps, CustomerPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      url: window.location,
      showPaymentForm: true,
      invoicesToBePaid: [],
      amountDue: 0,
      invoiceArray: [],
      redirect: false,
      invoiceTableKey: 123,
      paymentFormKey: 123
    };

    const { customerURL } = (this.props.match.params as any); 

    this.handleCheckboxCheckInvoice = this.handleCheckboxCheckInvoice.bind(this);
    this.handlePaymentRefresh = this.handlePaymentRefresh.bind(this);
    this.getAmountDue = this.getAmountDue.bind(this);

    this.fetchInvoices(customerURL).then((response) => {
       
        this.setState({invoiceArray: response});
    });
  } 

  async fetchInvoices (customerURL: string)  {
		console.log ("Calling fetch for "+ customerURL);
		let api_key = process.env.REACT_APP_API_KEY;
		let api_endpoint = process.env.REACT_APP_API_ENDPOINT;
		let	url = "https://".concat(api_endpoint || "").concat("/customer/" + customerURL);

		console.log("api_endpoint is ".concat(api_endpoint || "none"));
		if ((api_key !== undefined) && (api_endpoint !== undefined)) {
			const response = await fetch(url, {
				headers: {
				'Content-Type': 'application/json',	
				'x-api-key':api_key
				},
			});

			return await response.json();
		}
	}

  public updateInvoiceArrayState = (arrayOfInvoices: Array<Invoice>) => this.setState({invoicesToBePaid: arrayOfInvoices}); 

  handlePaymentRefresh() {
    const { customerURL } = (this.props.match.params as any);
    let invoiceArrayLocal = []; 
    if(this.state.invoiceArray.length === this.state.invoicesToBePaid.length){
      //if the customer pays all outstanding invoices, then they are redirected back to the base page
      if(invoiceArrayLocal.length === 0){
        this.setRedirect(); 
      }
    } else{
      this.fetchInvoices(customerURL).then((response) => {
        this.setState({invoiceArray: response});
        invoiceArrayLocal = response; 
        console.log("PaymentRefresh, update invoices."); 
      }).then(() => {
        //if there are still outstanding invoices to be paid, the table refreshes (removing the freshly paid invoices and only showing the remaining unpaid ones)
        const refreshKey = Math.random();
        this.setState({ invoiceTableKey: refreshKey, invoicesToBePaid: [] });
      }); 
    }
  }
  
  // logic to update the arrays of invoice data and invoice_ids upon check/un-check of the 'Include in Payment' box
  handleCheckboxCheckInvoice(isChecked: boolean, invoiceIndex: number) {

    let invoicesToBePaidArrayLocal: Array<Invoice> = [];

    //Copy the invoicesToBePaid array state
    invoicesToBePaidArrayLocal = this.state.invoicesToBePaid;

    if(isChecked){
      //push invoice/invoiceId to copied array
      invoicesToBePaidArrayLocal.push(this.state.invoiceArray[invoiceIndex]); 
    } else {
      //splice invoice/invoiceId from copied array
      invoicesToBePaidArrayLocal.splice(this.state.invoicesToBePaid.indexOf(this.state.invoiceArray[invoiceIndex]), 1); 
    }

    //update invoicesToBePaid array state with copied array + appended invoice
    this.updateInvoiceArrayState(invoicesToBePaidArrayLocal);

    this.getAmountDue(); 
  }

  // gets just the invoiceIDs from the array of full invoice data
  getInvoicesToBePaidIdArray () {
    let invoiceIdArray = new Array<number>();
    
    // iterates through the invoices to be paid and adds the invoice_id to the invoiceIDArray to be used by the BlueSnapAuthCapture lambda so that it knows what invoices are being paid
    this.state.invoicesToBePaid.map((invoice) => {
      invoiceIdArray.push(invoice.invoiceID); 
    })

    return invoiceIdArray; 
  }

  // sums the balances of the checked invoices to get the full amount to be paid
  // dynamically updates the amount next to the 'charge my card' button with the fresh amount upon checking/un-checking a box to 'include in payment'
  getAmountDue(): number {
    let amountArray = new Array<number>();
    this.state.invoicesToBePaid.map((invoice) => {
      amountArray.push(parseFloat(invoice.balanceDue)); 
    });

    // recursive function that sums all the elements in an array
    let amountDue = amountArray.reduce(function(a, b){
      return a + b;
    }, 0);

    //rounds to 2 places in the case of weird float roading to 10 radix
    amountDue = Math.round((amountDue + Number.EPSILON) * 100) / 100;

    // refreshes the amount in the 'Charge my card' button
    this.setState({amountDue: amountDue, paymentFormKey: Math.random()});

    return amountDue;
  }

  public setRedirect = () => {
		this.setState({ redirect: true});
	}

	public renderRedirect = () => {
		//Redirect to home page if all invoices are paid
		let url = "/"
		if (this.state.redirect) {
			return <Redirect to={url}/>
		}
  }
  
render() {
    return(<div>
        <div className="panel panel-default">
          <table className="table table-bordered">
						<thead>
							<tr>
								<th>Include in Payment?</th>
								<th>Invoice</th>
                <th>Due Date</th>
                <th>Balance Due</th>
              </tr>
            </thead>
						<tbody key={this.state.invoiceTableKey}>
              {
                this.state.invoiceArray.map((invoice, index) =>
                  <tr key={"InvoiceRow_" + index}>
                    <td key={"InvoiceCheckboxComponent_" + index} style={{width: "10%"}}>
                     <Checkbox onChangeFunction={this.handleCheckboxCheckInvoice} invoiceIndex={index}/>
                    </td>
                    <td key={"InvoiceId_" + index}>
                      <a href={this.state.url.origin.toString() + '/invoice/' + invoice.invoiceURL} >{invoice.invoiceID}</a>
                    </td>
                    <td key={"InvoiceDueDate_" + index}>{invoice.dueDate}</td>
                    <td key={"InvoiceBalanceDue_" + index}>${invoice.balanceDue}</td>
                  </tr>  
						    )
              }
						</tbody>
					</table>
        </div>
        {this.state.invoicesToBePaid.length > 0 ? <PaymentForm key={this.state.paymentFormKey}
                                                        invoiceUrl={this.state.invoicesToBePaid[0].invoiceURL} 
                                                        amountDue={this.state.amountDue} 
                                                        paymentRefreshAction={this.handlePaymentRefresh}
                                                        invoiceIdArray={this.getInvoicesToBePaidIdArray()}
                                                        isOnCustomerPage={true}/> 
          : console.log("Invoices to be paid has length <= 0")}
          {this.renderRedirect()}
        </div>
    )}
}