import React from 'react';
import { Invoice, InvoiceState } from "./Invoice"
import { FieldState, Hashtable } from './FieldState';


interface InvoiceSearchFormProps {
}

interface InvoiceSearchFormState {
	invoiceId: string,
	clientId: string,
  amount: string,
  // true to trigger query of DB
  doSearch: boolean,
  fieldStates : Hashtable<FieldState> 
}

export class InvoiceSearchForm extends React.Component<InvoiceSearchFormProps, InvoiceSearchFormState> {

  static INVOICE_ID:keyof InvoiceSearchFormState = "invoiceId";
  static CLIENT_ID:keyof InvoiceSearchFormState = "clientId";
  static AMOUNT:keyof InvoiceSearchFormState = "amount";

  static DEFAULT_STATE = {
    invoiceId: "1234",
    clientId: "123",
    amount: "999.99",
    doSearch: false,
    fieldStates: {
      [InvoiceSearchForm.CLIENT_ID]: {
        validationRegEx: new RegExp("^[0-9]+$") //require at least 1 digit
       },
      [InvoiceSearchForm.INVOICE_ID]: {
        validationRegEx: new RegExp("^[0-9]+$") //require at least 1 digit
      },
      [InvoiceSearchForm.AMOUNT]: {
        validationRegEx: new RegExp("^[0-9]+(\\.[0-9][0-9]?)?$")  //require at least 1 digit, with optionally 2 digits after the decimal
      }
    }
  };

  static FIELDS = [InvoiceSearchForm.CLIENT_ID, InvoiceSearchForm.INVOICE_ID, InvoiceSearchForm.AMOUNT];
  
  private invoiceElement: React.RefObject<Invoice<InvoiceSearchForm, InvoiceState>>;

  constructor(props: InvoiceSearchFormProps) {
    super(props);

    this.state = InvoiceSearchForm.DEFAULT_STATE;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.invoiceElement = React.createRef<Invoice<InvoiceSearchForm, InvoiceState>>();

  }

  private isValid(fieldName: keyof InvoiceSearchFormState, value:string) {
    if (value === InvoiceSearchForm.DEFAULT_STATE[fieldName]) return false;
    let validationRegEx = this.state.fieldStates[fieldName].validationRegEx;
    return validationRegEx !== undefined? validationRegEx.test(value): true;
  }

  handleChange (event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    const name = target.name as keyof InvoiceSearchFormState;
    const value = target.value;

    // update this field and include current state for rest of fields
    this.setState({
      ...this.state,
      [name]: value,
      fieldStates: {
        ...this.state.fieldStates,
        [name]: {
          isValid: this.isValid(name, value)
        }
      }
    });    
  }

  handleFocus(event: React.FormEvent<HTMLInputElement>) {
    event.currentTarget.select();
	}

  handleBlur (event: React.FormEvent<HTMLInputElement>) {
    // update the isValid state when we leave this field by calling handleChange
    this.handleChange (event);
  }

	handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // validate fields using regex
    let allFieldsValid = true;
    let fieldStates = this.state.fieldStates;
    InvoiceSearchForm.FIELDS.forEach((field) => { if (true !== fieldStates[field].isValid) allFieldsValid = false; });

    if (allFieldsValid) {
      // if we've already searched, we can't rely on componentDoMount and must trigger a new search in the Invoice component
      if (this.state.doSearch && (this.invoiceElement.current !== null)) {
        let invoiceComponent = this.invoiceElement.current;
        invoiceComponent.fetchInvoiceBySearchParameters({
          amount: this.state.amount,
          clientId: this.state.clientId,
          invoiceId: this.state.invoiceId
        }).then (response => invoiceComponent.handleFetchInvoiceResponse(response)).catch(response => invoiceComponent.handleFetchInvoiceError(response));
      }
      else {
        this.setState({
          ...this.state,
          doSearch: true,
        });
      }
    }
	}

  getFieldStyle (field: keyof InvoiceSearchFormState): string {
    let style = "form-control";
    if (this.state[field] === InvoiceSearchForm.DEFAULT_STATE[field]) 
      style = style.concat(" form-default");
    if (false === this.state.fieldStates[field].isValid)
      style = style.concat(" hosted-field-invalid");
    return style;
  }

  render() {
    const form =
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Search for your invoice</h3>
        </div>
        <form onSubmit={this.handleSubmit} className="panel-body">
          <div className="row">
            <div className="form-group col-md-2">
              <label>Client #</label><input type="text" name="clientId" className={this.getFieldStyle(InvoiceSearchForm.CLIENT_ID)} defaultValue={this.state.clientId} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={this.handleFocus}/>
            </div>
            <div className="form-group col-md-3">
              <label>Invoice #</label><input type="text" name="invoiceId" className={this.getFieldStyle(InvoiceSearchForm.INVOICE_ID)} defaultValue={this.state.invoiceId} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={this.handleFocus}/>
            </div>
            <div className="form-group col-md-4">
              <label>Original Invoice Amount</label><input type="text" name="amount" className={this.getFieldStyle(InvoiceSearchForm.AMOUNT)} defaultValue={this.state.amount} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={this.handleFocus}/>
            </div>
            <div className="form-group col-md-3" >
              <input type="submit" className="btn btn-success btn-md col-xs-8 search-button" value="Search"/>
            </div>
          </div>
        </form>
      </div>
    if (this.state.doSearch) 
      return (
        <div>
          {form}
          <br />
          <div>
            <Invoice amount={this.state.amount} clientId={this.state.clientId} invoiceId={this.state.invoiceId} ref={this.invoiceElement}/>
          </div>
        </div>
      );
    else 
      return (
        <div>
          {form}
        </div>
      );
	}
}
