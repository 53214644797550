import React from "react";
import { Payment } from "./Payment";
import { FieldState, Hashtable } from "./FieldState";
import { stringify } from "querystring";

// necessary to ignore type checking for Bluesnap external scripts
declare global {
  interface Window {
    bluesnap: any;
  }
}

interface PaymentFormProps {
  amountDue?: number;
  invoiceUrl: string;
  paymentRefreshAction: () => void;
  invoiceIdArray?: Array<number>;
  isOnCustomerPage: boolean; 
}

interface PaymentFormState extends Payment {
  isValidMonetaryValue: boolean;
  isValidAmountValue: boolean;
  showPartialPayment: boolean;
  errorMessage?: string;
  paymentStatus?:
    | "tokenError"
    | "requiredFields"
    | "paymentError"
    | "paymentSuccess"
    | "waitingForResponse";
    amountDue?: number;
  invoiceIdArray?: Array<number>;
  url: Location;
}

export class PaymentForm extends React.Component<
  PaymentFormProps,
  PaymentFormState
> {
  static AMOUNT = "amount";
  static FIRST_NAME = "firstName";
  static LAST_NAME = "lastName";
  static ZIP = "zip";
  static CREDIT_CARD = "ccn";
  static EXPIRATION = "exp";
  static CVV = "cvv";
  static FIELDS = [
    PaymentForm.AMOUNT,
    PaymentForm.FIRST_NAME,
    PaymentForm.LAST_NAME,
    PaymentForm.ZIP,
    PaymentForm.CREDIT_CARD,
    PaymentForm.EXPIRATION,
    PaymentForm.CVV,
  ];
  static CREDIT_CARD_IMAGES = {
    AMEX: "/images/amex.png",
    DINERS: "/images/diners_club.png",
    DISCOVER: "/images/discover.png",
    JCB: "/images/jcb.png",
    MASTERCARD: "/images/mastercard.png",
    VISA: "/images/visa.png",
    GENERIC: "/images/generic-card.png",
  };

  private fieldStates: Hashtable<FieldState> = {};
  private creditCardImage: string = PaymentForm.CREDIT_CARD_IMAGES["GENERIC"];

  constructor(props: PaymentFormProps) {
    super(props);
    this.state = {
      invoiceUrl: props.invoiceUrl,
      isValidMonetaryValue: true,
      isValidAmountValue: true,
      showPartialPayment: false,
      amountDue: props.amountDue,
      invoiceIdArray: props.invoiceIdArray,
      url: window.location,
    };

    // initialize our field validation hashtable
    PaymentForm.FIELDS.forEach((field) => {
      this.fieldStates[field] = {};
    });

    // regex for field validation for non hosted payment fields
    this.fieldStates[PaymentForm.AMOUNT].validationRegEx = new RegExp(
      /* this RegEx validates the Payment Amount. Particularly and especially relevant when the user is making a partial payment and is allowed to
         enter their own amount. The [\$]{0,1} allows a leading dollar sign, should the user feel so inclined. It is stripped before being sent to
         BlueSnap to avoid a parsing error. The [0-9]* allows any number of digits to denote the dollar amount. The [\.]{0,1} is an explicit decimal
         point, optional but necessary if the user wants to pay a fraction of a dollar. And the[0-9]{1,2} will denote the cents (minimum 1 digit, 
         maximum 2 digits). Finally the parentheses around the payment amount (excluding the leading $) establishes a 'group' that is utilized later
         to handle the aforementioned stripping of the $ sign. And, of course, the ^ to establish that the dollar sign must be at the beginning and 
         the $ to do the same for the cents at the end. */
      "^[\$]{0,1}([0-9]*[\.]{0,1}[0-9]{1,2})$"
    ); //require a positive money value
    this.fieldStates[PaymentForm.FIRST_NAME].validationRegEx = new RegExp(
      "^[a-zA-Z]+$"
    ); //require at least 1 letter
    this.fieldStates[PaymentForm.LAST_NAME].validationRegEx = new RegExp(
      "^[a-zA-Z]+$"
    ); //require at least 1 letter
    this.fieldStates[PaymentForm.ZIP].validationRegEx = new RegExp(
      "^[0-9]{5}$"
    ); //require 5 digits

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleBluesnapSubmit = this.handleBluesnapSubmit.bind(this);
    this.isValidMonetaryValue = this.isValidMonetaryValue.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

    //Make initial payment valid, if we check "Partial Payment" the regex will cause re-evaluate this value
    this.fieldStates["amount"].isValid = true;

  }

  /** This method sends the payment details to Bluesnap for auth/catpure.  */
  async postBluesnapPayment(
    paymentDetails: PaymentFormState
  ): Promise<Response | undefined> {
    let api_key = process.env.REACT_APP_API_KEY;
    let api_endpoint = process.env.REACT_APP_API_ENDPOINT;
    let base_url = "https://".concat(api_endpoint || "").concat("/payment/");
    if (api_key !== undefined && api_endpoint !== undefined) {
      const response = await fetch(base_url, {
        method: "POST",
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
        },
        body: JSON.stringify(paymentDetails),
      });
      return await response;
    }
  }

  /** This method handles the response from payment auth/capture. If we don't get a 200 success, we call the failure response  */
  async handlePostBluesnapPaymentSuccess(response: Response | undefined) {
    let errorMessage = undefined;
    console.log("In handleFetchBlueSnapTokenSuccess");
    if (response !== undefined) {
      console.log("status is " + response.status);
      // if we received an error response, call error handling code
      if (response.status !== 200) {
        console.error("received " + response.status + " from Bluesnap");
        let body = await response.json();
        console.error("message is " + JSON.stringify(body));
        errorMessage = body.message;
      } else {
        this.setState({
          paymentStatus: "paymentSuccess",
        });
        // tell the invoice component to hide this compenent after showing the payment success message for a few seconds.
        setTimeout(this.props.paymentRefreshAction, 2000);
        
        return;
      }
    }

    // if we landed here there was an unknown issue
    this.setState({
      ...this.state,
      paymentStatus: "paymentError",
      errorMessage: errorMessage,
    });
    return;
  }

  /** This method handles payment auth/capture failures or error responses */
  async handlePostBluesnapPaymentFailure(error: Error) {
    console.error(error);

    this.setState({
      ...this.state,
      paymentStatus: "paymentError",
    });
    return;
  }

  /** This method calls Bluesnap for a token to represent payment session.
   *  When hosted payment fields are submitted succesfully, the credit card details will be attached to this token  */
  async fetchBluesnapToken(
    paymentDetails: PaymentFormState
  ): Promise<Response | undefined> {
    console.log("Getting Bluesnap token");
    let api_key = process.env.REACT_APP_API_KEY;
    let api_endpoint = process.env.REACT_APP_API_ENDPOINT;
    let base_url = "https://".concat(api_endpoint || "").concat("/token/");

    if (api_key !== undefined && api_endpoint !== undefined) {
      const response = await fetch(base_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": api_key,
        },
        body: JSON.stringify(paymentDetails),
      });
      return response;
    }
  }

  /** If we are successful in getting a Bluensnap token, we will include the Bluesnap hosted JS
   * and trigger the Bluesnap code to setup the hosted payment fields */
  async handleFetchBluesnapTokenSuccess(response: Response | undefined) {
    let errorMessage = undefined;
    if (response !== undefined) {
      if (response.status === 200) {
        //console.log("body is " + response);
        let body = await response.json();

        if ("token" in body) {
          this.setState({
            ...this.state,
            token: body["token"],
          });

          let bluesnapDomain =
            process.env.BLUESNAP_DOMAIN || "sandbox.bluesnap.com";
          // load external Bluesnap hosted payment script
          let bluesnapScriptUrl = "https://"
            .concat(bluesnapDomain)
            .concat("/web-sdk/4/bluesnap.js");

          const script = document.createElement("script");
          script.src = bluesnapScriptUrl;
          script.async = true;
          script.onload = () => this.bluesnapScriptLoaded();

          document.body.appendChild(script);
          return;
        }
      } else {
        console.error("received " + response.status + " from BluesnapToken");
        let body = await response.json();
        errorMessage = body.message;
      }
    }
    // if we got here there was some kind of error
    this.setState({
      ...this.state,
      paymentStatus: "tokenError",
      errorMessage: errorMessage,
    });
  }

  handleFetchBluesanpTokenFailure(error: Error) {
    console.error(error);
    this.setState({
      ...this.state,
      paymentStatus: "tokenError",
    });
  }

  componentDidMount() {
    // grab a Bluesnap token used for payment auth/catpure.
    this.fetchBluesnapToken(this.state)
      .then((response) => this.handleFetchBluesnapTokenSuccess(response))
      .catch((response) => this.handleFetchBluesanpTokenFailure(response));
  }

  /* This is code provided by Bluesnap and called after the Bluesnap token is available and JS is loaded */
  bluesnapScriptLoaded() {
    var fieldStates = this.fieldStates;
    var thisForm = this;
    var bsObj = {
      token: this.state.token,
      onFieldEventHandler: {
        /*OPTIONAL*/ setupComplete: function () {
          console.warn("setupComplete");
        },
        /*OPTIONAL*/ threeDsChallengeExecuted: function () {
          console.warn("threeDsChallengeExecuted");
        },
        // tagId returns: "ccn", "cvv", "exp"
        onFocus: function (tagId: any) {}, // Handle focus
        onBlur: function (tagId: any) {}, // Handle blur
        // since errors for hosted payment fields are via called, we need to use forceUpdate to refresh the form when they change
        onError: function (
          tagId: string,
          errorCode: string,
          errorDescription: string
        ) {
          fieldStates[tagId].isValid = false;
          fieldStates[tagId].errorMessage = errorDescription;
          thisForm.forceUpdate();
        }, // Handle a change in validation
        /*errorCode returns:
                "10" --> invalidCcNumber, invalidExpDate, invalidCvv Dependent on the tagId;
                "22013" --> "CC type is not supported by the merchant"; 
                "14040" --> " Token is expired";
                "14041" --> " Could not find token";
                "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
                "400" --> "Session expired please refresh page to continue";
                "403", "404", "500" --> "Internal server error please try again later"; 
            */

        /* errorDescription is optional. Returns BlueSnap's standard error description */

        onType: function (
          tagId: any,
          cardType: keyof typeof PaymentForm.CREDIT_CARD_IMAGES,
          cardData?: any
        ) {
          thisForm.creditCardImage =
            PaymentForm.CREDIT_CARD_IMAGES[cardType] ||
            PaymentForm.CREDIT_CARD_IMAGES["GENERIC"];
          thisForm.forceUpdate();
          /* cardType will give card type, and only applies to ccn: AMEX, VISA, MASTERCARD, AMEX, DISCOVER, DINERS, JCB */
          if (null != cardData) {
            /* cardData is an optional parameter which will provide ccType, last4Digits, issuingCountry, isRegulatedCard, cardSubType, binCategory and ccBin details (only applies to ccn) in a JsonObject */
            //console.log(cardData);
          }
        },

        onValid: function (tagId: string) {
          fieldStates[tagId].isValid = true;
          thisForm.forceUpdate();
        }, // Handle a change in validation
      },
      style: {
        // Styling all inputs
        input: {
          "font-size": "14px",
          "font-family": "Helvetica Neue,Helvetica,Arial,sans-serif",
          "line-height": "1.42857143",
          color: "#555",
        },
        // Styling Hosted Payment Field input state
        ":focus": {
          color: "#555",
        },
      },
      ccnPlaceHolder: "1234 5678 9012 3456", //for example
      cvvPlaceHolder: "123", //for example
      expPlaceHolder: "MM/YY", //for example
    };
    window.bluesnap.hostedPaymentFieldsCreate(bsObj);
  }

  handleBlur(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    const name = target.name as keyof PaymentFormState;
    const value = target.value;

    let validationRegEx = this.fieldStates[name].validationRegEx;
    if (
      validationRegEx !== undefined &&
      this.fieldStates[name].isValid === undefined
    ) {
      this.fieldStates[name].isValid = validationRegEx.test(value);
      // manually call forceUpdate since we aren't using state for these form field validation states
      this.forceUpdate();
    }
  }

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.currentTarget;
    const name = target.name as keyof PaymentFormState;
    const value = target.value;

    let validationRegEx = this.fieldStates[name].validationRegEx;
    if (validationRegEx !== undefined) {
      this.fieldStates[name].isValid = validationRegEx.test(value);
    }
    // update this field and include current state for rest of fields
    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  /**
   * This is called on form submit to send hosted payment fields to Bluesnap
   */
  handleBluesnapSubmit() {
    window.bluesnap.hostedPaymentFieldsSubmitData((callback: any) =>
      this.bluesnapSubmitCallback(callback)
    );
  }

  /**
   * This is the callback for submission of hosted payment fields. If we receive a success,
   * our token is now associated to the credit card info submitted by the customer
   * and we are ready to call our payment lambda to auth/capture with Bluesnap
   * @param callback
   */
  bluesnapSubmitCallback(callback: any): Promise<void> {
    if (null != callback.cardData) {
      console.log(
        "Success tokenizing - card type: " + callback.cardData.ccType
      );
      // submit the form data to our payment service
      
      return this.postBluesnapPayment(this.state)
        .then((response) => this.handlePostBluesnapPaymentSuccess(response))
        .catch((response) => this.handlePostBluesnapPaymentFailure(response));
    } else {
      var errorArray = callback.error;
      for (var i in errorArray) {
        console.log(
          "Received error: tagId= " +
            errorArray[i].tagId +
            ", errorCode= " +
            errorArray[i].errorCode +
            ", errorDescription= " +
            errorArray[i].errorDescription
        );
      }
      return this.handlePostBluesnapPaymentFailure(
        new Error("Failure to tokenize fields")
      );
    }
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // don't do anything if submit was already fired and we are waiting for a response
    if (this.state.paymentStatus !== "waitingForResponse") {
      //Check to see if Partial Payment
      if(!this.fieldStates["amount"].isValid && !this.state.showPartialPayment) {
        this.fieldStates["amount"].isValid = true;
      }

      // all field must be valid before allowing submit
      if(this.state.showPartialPayment === false) {
        this.fieldStates["amount"].isValid = true;
        this.setState({amount: this.props.amountDue});
      }

      let allFieldsValid = true;
      PaymentForm.FIELDS.forEach((field) => {
        if (true !== this.fieldStates[field].isValid) allFieldsValid = false;
      });
      
      if (allFieldsValid) {
        if(this.state.isValidAmountValue && this.state.isValidMonetaryValue) {
          this.setState({
            ...this.state,
            paymentStatus: "waitingForResponse",
          });

          this.handleBluesnapSubmit();
        }
      }
    }
  }

  getFieldStyle(field: string): string {
    if (field === "amount") {
      if (this.state.isValidMonetaryValue && this.state.isValidAmountValue) {
        return "form-control";
      } else {
        return "hosted-field-invalid form-control";
      }
    }

    if (false === this.fieldStates[field].isValid)
      return "hosted-field-invalid form-control";
    else return "form-control";
  }

  getFieldError(field: string): string {
    if (false === this.fieldStates[field].isValid) {
      switch (this.fieldStates[field].errorMessage || "") {
        case "invalidCcNumber":
          return "Invalid credit card number.";
        case "invalidExpDate":
          return "Invalid expiration date.";
        case "invalidCvv":
          return "Invalid security code";
        default:
          return this.fieldStates[field].errorMessage || "";
      }
    } else return "";
  }

  isValidMonetaryValue(event: React.FormEvent<HTMLInputElement>) {
    /*
  Check's not only REGEX but makes sure the amount provided is valid
  */
    let validationRegEx = this.fieldStates[PaymentForm.AMOUNT].validationRegEx;
    let validMonetaryValue = false;

    let parsedVal = undefined;
    let parsedValBool = false;
    let parsedValFinal = undefined;
    
    //If regex exists
    if (validationRegEx !== undefined) {
      let val = event.currentTarget.value.match(validationRegEx);
      if (val){
        // [1] grabs the regex group defined by the parentheses in the regex pattern decalred in [PaymentForm.AMOUNT].validationRegEx
        parsedVal = val[1];
        parsedValBool = validationRegEx.test(parsedVal);

        if (parsedValBool === true) {
          validMonetaryValue = true;
        }
      } 
    }

    let validAmountValue = true;
      
      if(parsedVal !== undefined){
      //If the REGEX check is true, Comment out this check to test back-end amount validation
        if (parsedValBool === true && this.props.amountDue) {
          let paymentValue = parseFloat(parsedVal);
          //Checking for amount > 0 and < amountDue
          if (paymentValue > this.props.amountDue || paymentValue <= 0) {
            validAmountValue = false;
          } else {
            //validAmountValue = true;
            parsedValFinal = paymentValue;
          }
        } else {
          //ensuring correct error messages appear
          validMonetaryValue = false;
          validAmountValue = true;
        }
        
      } else {
        //ensuring correct error messages appear
        if (parsedValBool === true) {
          validMonetaryValue = true;
          validAmountValue = false;
        }
      }

      this.handleChange(event);

      //Accounting for empty field
      if(event.currentTarget.value === ""){
        validAmountValue = true;
      }

      this.setState({
        isValidAmountValue: validAmountValue,
        isValidMonetaryValue: validMonetaryValue,
        amount: parsedValFinal
      });
    
  }

  handleCheckboxChange(event: React.FormEvent<HTMLInputElement>) {
    let target = event.currentTarget.checked;
    if (target) {
      this.setState({ showPartialPayment: true });
    } else {
      this.setState({ showPartialPayment: false });
    }
  }

  render() {
    let paymentErrorMessage: string | undefined = undefined;
    switch (this.state.paymentStatus) {
      case "tokenError":
        return (
          <div>
            <h3>
              {this.state.errorMessage ||
                "There is a problem with our payment processor, please try again later."}
            </h3>
          </div>
        );
      case "paymentSuccess":
        return (
          <div>
            <h3>Thank you for your payment.</h3>
          </div>
        );
      case "requiredFields":
        paymentErrorMessage = "Please complete all required fields.";
        break;
      case "paymentError":
        paymentErrorMessage = this.state.errorMessage
          ? this.state.errorMessage
          : "Error authorizing your payment.";
        break;
    }

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <h3 className="panel-title">Pay your invoice</h3>
        </div>
        {paymentErrorMessage ? (
          <div className="alert alert-danger">{paymentErrorMessage}</div>
        ) : (
          ""
        )}
        <form
          onSubmit={this.handleSubmit}
          className="panel-body"
          id="checkout-form"
        >
          <div className="row">
            <div className="form-group col-md-5">
              <label>First</label>
              <input
                type="text"
                className={this.getFieldStyle(PaymentForm.FIRST_NAME)}
                name={PaymentForm.FIRST_NAME}
                defaultValue={this.state.firstName}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </div>
            <div className="form-group col-md-5">
              <label>Last</label>
              <input
                type="text"
                className={this.getFieldStyle(PaymentForm.LAST_NAME)}
                name={PaymentForm.LAST_NAME}
                defaultValue={this.state.lastName}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </div>
            <div className="form-group col-xs-5">
              <label>Zipcode</label>
              <input
                type="text"
                className={this.getFieldStyle(PaymentForm.ZIP)}
                name={PaymentForm.ZIP}
                defaultValue={this.state.zip}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
              />
            </div>
            <div className="form-group col-md-5">
              <label>Card Number</label>
              <div className="input-group">
                <div
                  className={this.getFieldStyle(PaymentForm.CREDIT_CARD)}
                  id="card-number"
                  data-bluesnap="ccn"
                ></div>
                <div id="card-logo" className="input-group-addon">
                  <img
                    src={this.creditCardImage}
                    height="20px"
                    alt="card logo"
                  />
                </div>
              </div>
              <span className="helper-text" id="ccn-help">
                {this.getFieldError(PaymentForm.CREDIT_CARD)}
              </span>
            </div>
            <div className="form-group col-xs-3">
              <label>Exp. Date</label>
              <div
                className={this.getFieldStyle(PaymentForm.EXPIRATION)}
                id="exp-date"
                data-bluesnap="exp"
              ></div>
              <span className="helper-text" id="exp-help">
                {this.getFieldError(PaymentForm.EXPIRATION)}
              </span>
            </div>
            <div className="form-group col-xs-3">
              <label>Security Code</label>
              <div
                className={this.getFieldStyle(PaymentForm.CVV)}
                id="cvv"
                data-bluesnap="cvv"
              ></div>
              <span className="helper-text" id="cvv-help">
                {this.getFieldError(PaymentForm.CVV)}
              </span>
            </div>
          </div>
          {this.state.paymentStatus === "waitingForResponse"  ? (
            <input
              type="submit"
              className="btn btn-warning btn-md col-xs-6 col-xs-offset-3"
              value="Sending your payment..."
            />
          ) : (
             <div className="container">
              { !this.props.isOnCustomerPage ? (
              <div className="row">
                <div className="form-group col-sm-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    onClick={this.handleCheckboxChange}
                  />
                  <label
                    className="form-check-label"
                    style={{ marginLeft: 6 }}
                    id="exampleCheck1"
                  >
                    Make partial payment
                  </label>
                </div>
                {this.state.showPartialPayment ? (
                  <div className="form-group col-sm-3">
                    <label style={{ marginLeft: 6 }}>Payment Amount</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className={this.getFieldStyle(PaymentForm.AMOUNT)}
                      name={PaymentForm.AMOUNT}
                      defaultValue={this.state.amount}
                      onChange={this.isValidMonetaryValue}
                      onBlur={this.handleBlur}
                    />
                    <span className="helper-text" id="exp-help">
                      {!this.state.isValidMonetaryValue ? "Invalid Amount" : ""}
                      {!this.state.isValidAmountValue
                        ? " Payment amount is greater than amount due or less than 0"
                        : ""}
                    </span>
                  </div>
                ) : (
                  <div/>
                )}
              </div>) : <div/>}
              <div className="row">
                <button className="btn btn-success btn-md" type="submit" style={{ width: "35%", marginTop: 10}}>
                  Charge my card <b>${this.state.showPartialPayment ? this.state.amount : this.state.amountDue}</b>
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}
