import React from 'react';
import './App.css';
import {  BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { InvoiceByUrlRoute } from "./InvoiceByUrlRoute";
import { InvoiceSearchForm} from "./InvoiceSearchForm"
import { CustomerPage } from './CustomerPage';

export class App extends React.Component  {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route path="/invoice/:invoiceUrl" component={InvoiceByUrlRoute} />
            <Route path="/invoice">
              <div>No invoice specified.</div>
            </Route>
            <Route path="/payment">
              Payment page goes here!
            </Route>
            <Route path="/customer/:customerURL" component={CustomerPage}></Route>
            <Route path="/" component={InvoiceSearchForm} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
