/* 
We needed to create a custom Checkbox component in order to perform actions onChange (i.e. when the box
is checked and then unchechecked). If we were to be using MaterialUI, the <Checkbox/> component they
provide has it's own onChange property and we would not have had to create a custom one, but the <input type="checkbox"/>
solution is not robust enough without customization. 
More can be read about this here: https://material-ui.com/components/checkboxes/ 
*/
import React from 'react';

export interface CheckboxProps {
    onChangeFunction: Function,
    invoiceIndex: number
        
}

export interface CheckboxState {
  isChecked: boolean,
}



export class Checkbox<P, S> extends React.Component<CheckboxProps, CheckboxState>{
    state={
        isChecked: false
    }

    toggleCheckboxChange = () => {
        this.props.onChangeFunction(!this.state.isChecked, this.props.invoiceIndex);
        this.setState({isChecked: !this.state.isChecked}); 
        
    }

    render() {
        return(
            <div>
                <input type="checkbox" checked={this.state.isChecked} onChange={this.toggleCheckboxChange}/>
            </div>
        );
    }

}